mat-dialog-container {
  padding: 0 !important;

  .mat-dialog-content {
    &.small {
      width: 500px;
    }
  }
}

@keyframes slide{
  0% {
    transform: translateX(-100%);
  }
    100%{
      transform: translateX(0);
    }
  }
  
.left-side-modal {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    width: 390px;

    .mat-dialog-container {
      border-radius: 0;
    }

      &.cdk-overlay-pane{
        transform: translateX(-100%);
        animation: slide 0.5s forwards;
      }
     
      
}

