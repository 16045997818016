mat-chip-list {
  .mat-chip-list-wrapper {
    margin: auto;
    .mat-chip-input {
      margin: 0 4px !important;
    }
  }

  &.mt-15p {
    .mat-chip-list-wrapper {
      margin-top: 15px;
    }
  }

  .mat-standard-chip {
    &.default {
      font-weight: normal;
      color: mat-color($mat-grey, A100);
      font-size: 10px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 4px;
      padding-left: 8px;
      min-height: 24px;

      .mat-chip-remove {
        color: mat-color($mat-grey, A200);
        opacity: 1;
        width: 16px;
        height: 16px;
        font-size: 16px;

        &:hover {
          color: #fff;
          opacity: 1 !important;
        }
      }

      &:hover {
        background: mat-color($mat-grey, A100);
        color: #fff;
        opacity: 1;

        .mat-chip-remove {
          color: #fff;
          opacity: 1;
        }
      }
    }
  }
}
