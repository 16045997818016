// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';

// Plus imports for other components in your app.
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$mat-grey: (
  50: #FAFAFA,
  100: #F7F7F7,
  200: #F2F2F2,
  300: #EEEEEE,
  250: #E6E6E6,
  400: #EBEBEB,
  500: #DFDFDF,
  600: #DADADA,
  700: #B1B1B1,
  750: #ADADAD,
  800: #9B9B9B,
  900: #979797,
  950: #7F7F7F,
  A100: #6C6C6C,
  A200: #525252,
  A300: #404040,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    950: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
  )
);

$mat-pink: (
  50: #fce4ec,
  100: #f8bbd0,
  150: #FFECF2,
  200: #f48fb1,
  300: #FF4886,
  400: #ec407a,
  500: #e91e63,
  600: #D0021B,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  950: #821C3D,
  A50: #FBE6E8,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A600: #EC4F82,
  A700: #c51162,

  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A600: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-red: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #d0021b,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-green: (
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #00B500,
  600: #43a047,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$mat-brown:  (
  50 : #f2ece9,
  100 : #decfc7,
  200 : #c8afa2,
  300 : #b28e7c,
  400 : #a27660,
  500 : #915e44,
  600 : #89563e,
  700 : #7e4c35,
  800 : #74422d,
  900 : #62311f,
  A100 : #ffb9a1,
  A200 : #ff936e,
  A400 : #ff6c3b,
  A700 : #ff5922,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
  // Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Yeti-primary: mat-palette($mat-grey, A100);
$Yeti-accent: mat-palette($mat-pink, A200, A100, A400);

$Yeti-info: mat-palette($mat-cyan);
$Yeti-warn: mat-palette($mat-orange);
$Yeti-error: mat-palette($mat-red);
$Yeti-success: mat-palette($mat-green);
$Yeti-brown: mat-palette($mat-brown);
$black: #000;
$white: #fff;

$Yeti-colors: (
  primary: map-get($Yeti-primary, A100),
  accent: map-get($Yeti-accent, 500),
  warn: map-get($Yeti-warn, 500),
  info: map-get($Yeti-info, 500),
  success: map-get($Yeti-success, 500),
  error: map-get($Yeti-error, 500),
  brown:  map-get($Yeti-brown, 500),
);

// Create the theme object (a Sass map containing all of the palettes).
$Yeti-theme: mat-light-theme($Yeti-primary, $Yeti-accent, $Yeti-warn);

//Icons size
.icon-xs,
.xs-icon {
  font-size: 18px;
}
.icon-sm,
.sm-icon {
  font-size: 20px;
}
.icon-md,
.md-icon {
  font-size: 24px;
}
.icon-lg,
.lg-icon {
  font-size: 30px;
}
.icon-xl,
.xl-icon {
  font-size: 36px;
}

//font weight
$weights: 300, 400, 500, 700;
@each $weight in $weights {
  .font-#{$weight} {
    font-weight: #{$weight} !important;
  }
}

//font size and line height
$sizes: 10, 12, 14, 16, 18,20,22,24;
@each $size in $sizes {
  .fs-#{$size} {
    font-size: #{$size}px !important;
  }
  .lh-#{$size} {
    line-height: #{$size}px !important;
  }
}

// custom margins and paddings
$spaceValues: 10, 15, 20, 25, 30;
@each $space in $spaceValues {
  .mt-#{$space} {
    margin-top: #{$space}px !important;
  }
  .mb-#{$space} {
    margin-bottom: #{$space}px !important;
  }
  .ml-#{$space} {
    margin-left: #{$space}px !important;
  }
  .mr-#{$space} {
    margin-right: #{$space}px !important;
  }
  .pt-#{$space} {
    padding-top: #{$space}px !important;
  }
  .pb-#{$space} {
    padding-bottom: #{$space}px !important;
  }
  .pl-#{$space} {
    padding-left: #{$space}px !important;
  }
  .pr-#{$space} {
    padding-right: #{$space}px !important;
  }
}

.text-decoration-underline {
  text-decoration: underline;
}
.v-align-middle {
  vertical-align: middle;
}
.v-align-text-bottom {
  vertical-align: text-bottom;
}
