mat-horizontal-stepper {
  border-radius: 4px 4px 0px 0px;

  .mat-horizontal-stepper-header-container {
    border-bottom: 1px solid map-get($mat-grey, 600);
    padding: 0px 150px;
    border-radius: 4px 4px 0px 0px;
    background: map-get($mat-grey, 100);

    .mat-horizontal-stepper-header {
      flex-direction: column;
      height: 56px;

      .mat-step-icon {
        margin-bottom: 5px;
        margin-top: 10px;
        font-size: 10px;
        width: 18px;
        height: 18px;

        &.mat-step-icon-state-number {
          background: map-get($mat-grey, 300);
          color: map-get($mat-grey, 800) !important;
        }

        &.mat-step-icon-selected {
          background: map-get($mat-grey, A100);
          color: #fff !important;
        }

        &.mat-step-icon-state-edit {
          background: map-get($mat-green, 500);
          color: #fff !important;
        }
      }

      .mat-step-label {
        font-size: 12px;
        font-weight: 400;
      }

      .mat-step-label {
        color: map-get($mat-grey, 800);
      }

      .mat-step-label-active {
        color: map-get($mat-green, 500);
      }

      .mat-step-label-selected {
        color: map-get($mat-grey, A100);
      }
    }
  }

  .mat-horizontal-content-container {
    padding: 0px;

    .mat-horizontal-stepper-content {
      form {
        padding: 24px 50px 0px 50px;

        @media (min-width: 1200px) {
          padding: 24px 170px 0px 170px;
        }

        .field-container {
          padding: 22px 38px;
          margin-bottom:16px;

          .mat-select {
            .mat-select-placeholder {
              font-size:14px;
            }
          }

          mat-label,
          .mat-form-field-label > span,
          .mat-input-element,
          .mat-slide-toggle-content span {
            font-size: 14px;
          }
        }
      }
    }
  }

  .mat-horizontal-stepper-actions {
    padding-top: 14px;
    .col {
      padding: 0px;
    }

    button {
      border-radius: 0px;

      &.previous {
        background: map-get($mat-grey, 400);
        color: map-get($mat-grey, 800);
      }
    }
  }

  .mat-step-header {
    pointer-events: none !important;

    &:hover, &:focus {
      background-color: transparent;
    }
  }
}

mat-horizontal-stepper {
  @for $i from 1 through 10 {
    &.selected-index-#{$i} {
      @for $j from 1 through $i {
        .mat-stepper-horizontal-line:nth-of-type(#{$j}) {
          border-color: map-get($mat-grey, A100);
        }
      }
    }
  }
}

.mat-card {
  .mat-horizontal-stepper-content {
    form {

      @media (min-width: 1200px) {
        padding: 0;
      }
    }
  }
}
