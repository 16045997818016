@import 'variables';

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($Yeti-theme);

.text-black {
  color: $black;
}
.bg-black {
  background-color: $black;
}
.text-white {
  color: $white;
}
.bg-white {
  background-color: $white;
}

.fluid-container {
  max-width: 1900px;
  margin: 0 auto;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,.6) !important;
}
@media (max-width: 1440px) {
  .px-xl-5.px-xl-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@each $name, $color in $Yeti-primary {
  .bg-primary-#{$name} {
    background: #{$color} !important;
  }

  .text-primary-#{$name} {
    color: #{$color} !important;
  }
  .border-primary-#{$name} {
    border-color: #{$color} !important;
  }
}

@each $name, $color in $Yeti-accent {
  .bg-accent-#{$name} {
    background: #{$color} !important;
  }

  .text-accent-#{$name} {
    color: #{$color} !important;
  }
  .border-accent-#{$name} {
    border-color: #{$color} !important;
  }
}

@each $name, $color in $Yeti-colors {
  .text-#{$name} {
    color: #{$color} !important;
  }
}


@import 'cards';
@import 'modal';
@import 'table';
@import 'tabs';
@import 'stepper';
@import 'inputs';
@import 'dialog';
@import 'spinner';
@import 'form';
@import 'chips';
@import 'expansion';
