mat-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }

  &.mat-form-field-invalid {
    .mat-form-field-label {
      color: map-get($mat-grey, 800) !important;
    }

    .mat-form-field-flex {
      background: map-get($mat-red, 50);
    }

    .mat-form-field-suffix {
      .warning {
        color: map-get($mat-red, 500);
        cursor: pointer;
      }
    }
  }

  &.transparent {
    &.mat-focused {
      .mat-form-field-flex {
        background: transparent !important;
      }
    }
  }

  .mat-form-field-suffix {
    margin-right: 5px;
    font-size: 16px;
  }

  .mat-form-field-prefix {
    margin-left: 5px;
  }

  .mat-form-field-underline {
    z-index: 2;
    bottom: 0px !important;
  }
}

mat-slide-toggle {
  padding-bottom: 25px;
}

mat-error {
  color: map-get($mat-red, 500) !important;
}
