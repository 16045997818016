mat-card {
  color: $black !important;
  box-shadow: none !important;
  border: 0.5px solid mat-color($mat-grey, 600);

  mat-card-header {
    margin: -16px -16px 0 -16px !important;
    padding: 18px 22px 20px 22px;
    border-radius: 4px 4px 0 0;

    mat-card-title {
      font-size: 16px;
      line-height: 24px;
      margin: 0 !important;
    }

    mat-card-subtitle {
      font-size: 12px;
      line-height: 24px;
      margin: 0 !important;
    }

    .close {
      position: absolute !important;
      right: 25px;
      font-size: 25px;
      cursor: pointer;
      color: mat-color($mat-grey, A100);

      &.mat-icon-button {
        top: 12px;

        .mat-button-focus-overlay {
          background-color: transparent;
        }

        &:hover {
          background: mat-color($mat-grey, 250);
        }
      }
    }
  }

  &.document-main-details {
    .mat-card-header {
      height: 82px !important;

      .mat-card-title {
        line-height: 24px;
      }

      .mat-card-header-text {
        margin: 0 0 0 16px;
      }
    }

    .mat-card-content {
      line-height: 14px;
    }

    .line-height-address {
      line-height: 18px;
    }

    .overview-block {
      i {
        margin: -6px 22px -4px 0 !important;
      }

      .divider {
        padding-bottom: 15px;
        margin: 0 0 15px 48px;
      }
    }

    .row-actions {
      margin-left: 50px;
    }

    .margin-icon-left {
      margin-left: 46px;
    }

    .material-icons {
      vertical-align: middle;
    }

    .icon.text-success {
      margin-left: -32px !important;
      margin-right: 8px !important;
    }

    .icon.text-error.material-icons {
      margin-right: 10px !important;
      margin-left: -22px !important;
    }

    .info-col-wrapper {
      margin: 0 -38px !important;
      padding-right: 84px !important;

      .info-col {
        padding-left: 38px !important;
      }
    }

    .expand-button {
      margin: 23px 0 23px 48px;
      border: 1px solid mat-color($mat-grey, 600);
      border-radius: 4px;
      background: mat-color($mat-grey, 300);
      height: 24px;
      cursor: pointer;
      display: none;
    }

    .hidden {
      display: none !important
    }

    .visible {
      display: unset !important;
    }

    .comments-last-row {
      display: none;
    }

    .comments-wrapper,
    .comments-last-row {
      .row {
        margin-bottom: 7px;
      }
    }

    @media (min-width: 1201px) {
      .hidden {
        display: block !important;
      }
    }

    @media (max-width: 1201px) {
      .expand-button {
        display: block;
      }
      .last-order {
        display: none;
      }
      .comments-wrapper {
        display: none;
      }
      .comments-last-row {
        display: flex;
      }

    }

    @media (max-width: 956px) {
      .col-sm.value {
        text-overflow: ellipsis;
      }
      .info-col-wrapper {
        margin: 0 -38px !important;
        padding-right: 58px !important;

        .info-col {
          padding-left: 10px !important;
        }
      }
      .mat-card-header-text {
        margin: 0 0 0 -10px !important;
      }
      .overview-block {
        i {
          margin-right: 5px !important;
        }

        .margin-icon-left,
        .divider {
          margin-left: 29px;
        }
      }
      .edit.mat-icon-button {
        margin-right: -13px !important;
      }
      .header-margin-right {
        margin-right: -15px;
      }
    }

  }

  &.modal-content {
    border: none !important;

    .mat-card-header {
      padding: 13px 15px 14px 30px;
      min-height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close {
        position: relative !important;
        top: auto;
        right: auto;
      }
    }

    .mat-card-header-text {
      margin-left: 0;
    }

    mat-card-title {
      font-size: 16px !important;
      line-height: 24px !important;
      font-weight: 400;
      margin-bottom: 0 !important;
    }

    mat-card-subtitle {
      font-size: 14px;
      line-height: 18px;
      margin: 0 !important;
    }

    mat-card-content {
      padding: 22px 100px;
      margin: 0 -16px;
    }

    @media (max-width: 1440px) {
      .mat-card-header {
        padding: 13px 15px 14px 30px;
      }
      mat-card-content {
        padding: 22px 70px;
      }
    }
    @media (max-width: 1024px) {
      .mat-card-header {
        padding: 13px 15px 14px 30px;
      }
      mat-card-content {
        padding: 22px 40px;
      }
    }

    @media (max-width: 960px) {
      .mat-card-header {
        padding: 13px 15px 14px 30px;
      }
      mat-card-content {
        padding: 22px 20px;
      }
    }

  }

  mat-card-content {
    padding: 22px;
    margin: 0;
  }

  mat-card-footer {
    padding: 15px 38px;
  }

  mat-card-actions {
    display: flex !important;
    margin: 0 -16px -16px -16px !important;

    button {
      flex: 1 1 auto !important;
      margin: 0 !important;
      border-radius: 0 !important;
    }
  }

}

mat-expansion-panel.expansion-panel {
  box-shadow: none !important;
  border: 0.5px solid mat-color($mat-grey, 600);
  border-radius: 4px !important;

  .small-table-expanded-content {
    padding: 20px 0 0 76px;

    .properties {
      margin: 15px 0;

      .label,
      .value {
        padding: 5px 0;
      }

      .label {
        color: mat-color($mat-grey, A100);
      }

      .value {
        font-weight: bold;
      }
    }
  }

  .large-table-expanded-content {
    padding: 20px 0 0 57px;

    .properties {
      margin: 15px 0;

      .label,
      .value {
        padding: 5px 0;
      }

      .label {
        color: mat-color($mat-grey, A100);
      }

      .value {
        font-weight: bold;
      }
    }
  }

  mat-expansion-panel-header {
    position: relative;
    padding-left: 32px;

    .mat-expansion-indicator {
      position: absolute;
      left: 15px;
      top: 50%;
      margin-top: -20px;
      margin-left: 5px !important;
      margin-right: 3px !important;

      &::after {
        content: "\e908";
        color: black;
        font-family: 'icomoon' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border: none !important;
        transform: rotate(0) !important;
      }
    }

    mat-panel-title {
      line-height: 18px;
      padding-left: 25px;
      color: black;

      .panel-title {
        font-size: 16px;
      }

      .panel-subtitle {
        font-size: 12px;
        margin-top: 5px;
      }
    }

    mat-panel-description {
      justify-content: flex-end;
      display: none;
    }
  }

  .mat-expansion-panel-body {
    padding: 16px 24px;
  }

  &.full-width-content {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }

  &.mat-expanded {
    mat-expansion-panel-header {
      background: mat-color($Yeti-primary, 100);
      border-bottom: 1px solid mat-color($Yeti-primary, 600);
      border-radius: 4px 4px 0 0;

      mat-panel-description {
        display: flex;
        z-index: 10;
      }

      &:hover,
      &:focus {
        background: mat-color($Yeti-primary, 100);
      }
    }

    .mat-expansion-indicator {
      &::after {
        content: "\e92d";
      }
    }
  }


}
