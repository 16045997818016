mat-spinner {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-top: -50px !important;
  margin-left: -50px !important;

  &.initial {
    margin: 0 !important;
    top: 0;
    left: 0;
  }

  &.centered {
    margin: 0 auto;
    left: 0;
  }
}
