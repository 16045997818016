.expanded-margin {
  margin-left: 57px;
}
.no-border.mat-row {
  border-bottom-style: hidden !important;

  &:hover {
    background: transparent !important;
  }
}

.no-border.mat-table {
  .mat-row {
    border-bottom-style: hidden !important;
  }
}

.large-screen-table.mat-table {
  .mat-header-row {
    height: 40px;
    background: transparent;
    border-bottom: 2px solid mat-color($mat-grey, 600) !important;
  }

  .mat-header-cell {
    border-top: none !important;
    padding-left: 62px !important;
    border-top: transparent;
    color: map-get($mat-grey, A100) !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .mat-cell {
    color: map-get($mat-grey, A100) !important;
    padding-left: 62px !important;
  }
}

.small-screen-table {
  .mat-header-row {
    height: 40px;
  }
  th {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px !important;
    line-height: 14px;
    color: map-get($mat-grey, A100) !important;
  }

  th,
  td {
    color: map-get($mat-grey, A100);

    &:first-child {
      padding-left: 48px !important;
    }

    &:last-child {
      padding-right: 48px !important;
    }
  }
}
.mat-table {
  width: 100%;
  border-collapse: collapse;

  .mat-header-row {
    min-height: 40px;
    background: map-get($Yeti-primary, 100);

    .mat-header-cell {
      color: $black;
      border-top: 1px solid map-get($Yeti-primary, 600);
    }
  }

  .mat-footer-row,
  .mat-row {
    align-items: stretch;
    min-height: 50px;
    background: inherit;
  }

  .mat-cell:first-of-type,
  .mat-footer-cell:first-of-type,
  .mat-header-cell:first-of-type {
    padding-left: 24px;
  }

  .mat-cell,
  .mat-header-cell {
    text-align: left;
    background: inherit;

    mat-checkbox {
      label {
        margin-bottom: 0;
      }
    }
  }

  .mat-header-cell {
    padding: 0 10px;
  }

  .mat-cell {
    padding: 10px;
  }

  &.small-table-row {
    .mat-header-row,
    .mat-footer-row,
    .mat-row {
      height: 32px;
    }
  }
}

.mat-paginator-outer-container {
  display: flex;
}

.mat-paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 56px;
  padding: 0 8px;
  flex-wrap: wrap-reverse;
  width: 100%;
}

.mat-paginator-page-size {
  display: flex;
  align-items: baseline;
  margin-right: 8px;
}

.mat-paginator-page-size-label {
  margin: 0 4px;
}

.mat-paginator-range-actions {
  display: flex;
  align-items: center;
}

.mat-paginator-range-label {
  margin: 0 32px 0 24px;
}

.mat-cell-contents {
  width: 100%;
}

.row-actions-container {
  position: relative;
  height: 100%;

  .row-actions {
    height: 100%;
    align-items: center;
    padding: 10px 15px 10px 70px;
    position: absolute;
    top: 0;
    right: 0;
    white-space: nowrap;
    display: none;
    background: -moz-linear-gradient(
        left,
        rgba(30, 87, 153, 0) 0%,
        rgba(36, 92, 156, 0) 1%,
        rgba(255, 255, 255, 0.94) 25%,
        rgba(255, 255, 255, 1) 27%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        rgba(30, 87, 153, 0) 0%,
        rgba(36, 92, 156, 0) 1%,
        rgba(255, 255, 255, 0.94) 25%,
        rgba(255, 255, 255, 1) 27%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        rgba(30, 87, 153, 0) 0%,
        rgba(36, 92, 156, 0) 1%,
        rgba(255, 255, 255, 0.94) 25%,
        rgba(255, 255, 255, 1) 27%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001e5799', endColorstr='#ffffff', GradientType=1);

    i {
      font-size: 20px;
      color: map-get($Yeti-primary, A100);
    }
  }
}

.mat-row {
  &:hover {
    background: map-get($Yeti-primary, 100);

    .row-actions {
      display: flex;
    }
  }
}
