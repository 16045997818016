mat-form-field {
  .mat-input-element,
  .mat-select {
    padding: 12px 6px 5px 6px;
  }
  .mat-form-field-label {
    padding: 12px 6px 6px 6px;
  }

  textarea {
    &.mat-input-element {
      padding: 10px 6px 6px 6px;
      margin: 2px 0px;
    }
  }

  .mat-form-field-empty {
    padding-top: 12px;
    padding-left: 6px;
  }

  &.mat-focused {
    .mat-form-field-flex {
      background-color: map-get($mat-grey, 100) !important;
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-ripple {
      background-color: map-get($mat-red, A700) !important;
    }

    .mat-input-element {
      caret-color: map-get($mat-red, A700);
      color: map-get($mat-red, A700);
    }
  }

  &.mat-form-field-should-float {
    .mat-form-field-label {
      padding-top: 12px !important;
      color: map-get($mat-grey, 700);
    }
  }
}

mat-select {
  &.mat-select-invalid {
    .mat-select-arrow {
      color: map-get($mat-red, A700) !important;
    }
  }
}


button {
  &.alt-btn {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 400;
  }
}
.mat-icon-button {
  width: 36px !important;
  height: 36px !important;
  .mat-button-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background: mat-color($mat-grey, 250);
  }
  .mat-icon,
  i {
    font-size: 24px;
  }

}

